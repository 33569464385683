
// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// BlocMapContact1 Module

(function ($) {

    "use strict";

    var self;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function BlocMapContact1() {

        self = this;

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    BlocMapContact1.prototype.init = function () {

        var mapHolder = $('#map');

        var marker_Lat = mapHolder.data( "marker-lat" );
        var marker_Lng =  mapHolder.data( "marker-long" );

        var mapcenter_Lat = mapHolder.data( "mapcenter-lat" );
        var mapcenter_Lng =  mapHolder.data( "mapcenter-long" );

        var app_adress =  mapHolder.data( "adress" );

        var isDraggable = true;

        var mapSettings = {
            linkToMap : 'https://www.google.ca/maps/dir/\'\'/'+encodeURIComponent(app_adress)+'/',
            pinImage : '/assets/images/google-marker.png'
        };

        if( $(window).width() <= 799 ){
            mapcenter_Lat = marker_Lat;
            mapcenter_Lng =  marker_Lng;
            isDraggable = false;
        }

        if (mapHolder.length) {

            //MAP
            var initialize = function() {
                var mapOptions = {
                    zoom: 16,
                    scrollwheel: false,
                    overviewMapControl: false,
                    panControl: false,
                    draggable : isDraggable,
                    disableDefaultUI: true,
                    rotateControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    zoomControl: true,
                    center: new google.maps.LatLng( mapcenter_Lat, mapcenter_Lng ),
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                };

                var map = new google.maps.Map(document.getElementById('map'),mapOptions);

                var myLatLng = new google.maps.LatLng( marker_Lat, marker_Lng );

                var hoffmanMarker = new google.maps.Marker({
                  position: myLatLng,
                  map: map,
                  icon: mapSettings.pinImage
              });
              google.maps.event.addListener(hoffmanMarker, 'click', function () {
                  window.open(mapSettings.linkToMap, '_blank');
              });
               // Retrive the center location
              google.maps.event.addListener(map, "center_changed", function() {
                console.log( map.getCenter().toUrlValue() );
              });
            };

            google.maps.event.addDomListener(window, 'load', initialize);

        }

    };

    this.BlocMapContact1 = new BlocMapContact1();

}.bind(Deckperfo, jQuery)());
