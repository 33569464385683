
// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// MobileMenu Module

(function ($) {

    "use strict";

    var self;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function MobileMenu() {

        self = this;

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    MobileMenu.prototype.init = function () {

        var mobileStatus = true;

        $('.switch-mobile').click(function()
        {
            if(mobileStatus == true)
            {
                $(this).find('.open').hide();
                $(this).find('.close').show();
                $('.mobile-menu').addClass('active');
                mobileStatus = false;
            }else if(mobileStatus == false)
            {
                $(this).find('.open').show();
                $(this).find('.close').hide();
                $('.mobile-menu').removeClass('active');
                mobileStatus = true;
            }
            
        });

    };

    this.MobileMenu = new MobileMenu();

}.bind(Deckperfo, jQuery)());