
// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// Hero1 Module

(function ($) {

    "use strict";

    var self;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function Hero1() {

        self = this;

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    Hero1.prototype.init = function () {

        if ($(window).width() > 1000) {

            $(window).scroll(function() {
                var scroll = $(window).scrollTop();
               /* $(".hero-animation").css({
                    backgroundSize: (100 + scroll/30)  + "%",
                });*/
                /*$(".overlay-opacity").css({
                    opacity: (0.4 + scroll/900),
                });*/
            });

        }

    };

    this.Hero1 = new Hero1();

}.bind(Deckperfo, jQuery)());
