
// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// MainNav1 Module

(function ($) {

    "use strict";

    var self,
        $nav,
        buffer,
        lastScrollTop;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function MainNav1() {

        self = this;
        $nav = $('#main-nav');
        buffer = 10;
        lastScrollTop = 0;

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    MainNav1.prototype.init = function () {

        this.checkIfFollow();
        this.addEventHandler();

    };

    // ------------------------------------
    // EVENT HANDLERS
    // ------------------------------------

    MainNav1.prototype.addEventHandler = function () {

        self = this;

        $(window).scroll(function(){

            self.checkIfFollow();

            var st = $(this).scrollTop();

            if (st > lastScrollTop){
              // downscroll code
              $nav.addClass( "hide-nav-bar" );
            } else if( st < ( lastScrollTop - buffer ) ){
              // upscroll code
              $nav.removeClass( "hide-nav-bar" );
            }

            if(st <= 15){
              $nav.removeClass( "hide-nav-bar" );
            }

            lastScrollTop = st;

        });

    };

    $('.link.search').click(function() {
            
        var _icon_search = $(this).find('.icon_search'),
            _icon_close = $(this).find('.icon_close'),
            _search = $('#search-input');

        if ( _search.hasClass('open') ) {

            _icon_close.hide();
            _icon_search.show();
            _search.removeClass('open');
        } else {

            _icon_search.hide();
            _icon_close.show();
            _search.addClass('open').find('input').focus();
        }

    });


    // ------------------------------------
    // METHODS
    // ------------------------------------

    MainNav1.prototype.checkIfFollow = function () {

        self = this;

        var st = $(window).scrollTop();

        if( st >= 150 ){
            $nav.addClass('follow');
        }else{
            $nav.removeClass('follow');
        }

    };

    this.MainNav1 = new MainNav1();

}.bind(Deckperfo, jQuery)());

