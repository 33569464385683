
// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// OverlayForm Module

(function ($) {

    "use strict";

    var self;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function OverlayForm() {

        self = this;

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    OverlayForm.prototype.init = function () {

        $( '.show-overlay-form' ).on( 'click', function(e){

            e.preventDefault();
            $('.overlay-form').addClass('active');

        });
        $( '.close' ).on( 'click', function(){

            $('.overlay-form').removeClass('active');

        });
        $( '.overlay-filter' ).on( 'click', function(){

            $('.overlay-form').removeClass('active');

        });

    };

    this.OverlayForm = new OverlayForm();

}.bind(Deckperfo, jQuery)());