
// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// MobileMenu Module

(function ($) {

    "use strict";

    var self;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function BlocConcours() {

        self = this;

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

  BlocConcours.prototype.init = function () {
        var $message = $('#mce-error-checkbox-response');
        var $checkbox = $('.rules-checkbox');
        var $form = $('.mailchimp-form-contest');

        $form.on('submit', function(e) {
          e.preventDefault();
          console.log('Should prevent');

          if($checkbox.is(':checked')) {
            console.log('Should Submit');
            $form.submit();
            $message.hide;
          } else {
            console.log('Should Not submit');
            $message.show();
            return false;
          }
        });
    };

    this.BlocConcours = new BlocConcours();

}.bind(Deckperfo, jQuery)());
