/**
 * Burger Navigation
 * ===========================================
 *
 */

// Namespacing
window.AjaxControls = window.AjaxControls  || {};

$(document).ready(function() {

    // Burger nav menu

    (function ($) {

        "use strict";

        var self,
            container,
            ajaxWrapper,
            showMore,
            showLess,
            routeUrl;

        // ------------------------------------
        // CONSTRUCTOR
        // ------------------------------------

        function AjaxShowNews() {

            self        = this;
            container   = '#bloc-publications';
            ajaxWrapper = '#ajax-wrap';
            showMore    = '[data-show-more]';
            showLess    = '[data-show-less]';
            routeUrl    = null;

        }

        // ------------------------------------
        // INIT
        // ------------------------------------

        AjaxShowNews.prototype.init = function () {

            self = this;

            self.addEventHandler();

        };

        // ------------------------------------
        // EVENT HANDLERS
        // ------------------------------------

        AjaxShowNews.prototype.addEventHandler = function () {

            self = this;

            // Show More
            $(container).on('click', showMore + ',' + showLess, function(event) {
                event.preventDefault();

                if( typeof $(this).attr('data-show-less') !== typeof undefined ){
                    $(ajaxWrapper).empty().append( '<div class="site-btn-center" ><div id="ajax-loader"><div class="loader-container"><div class="progress float shadow"><div class="progress__item"></div></div></div></div></div>' );
                }else{
                    $(ajaxWrapper).find('.site-btn-center').empty().append( '<div id="ajax-loader"><div class="loader-container"><div class="progress float shadow"><div class="progress__item"></div></div></div></div>' );
                }

                $.ajax({
                  type: 'POST',
                  url: $(this).data('url'),
                  data: "q=1&section="+$(this).data('section')+"&categorie="+$(this).data('categorie')+"&slug="+$(this).data('slug')+"&page="+$(this).data('show')+"&type="+$(this).data('type'),
                  success: function(data){

                    // on success use return data here
                    setTimeout(function(){
                      $(ajaxWrapper).find('.site-btn-center').remove();
                      $(ajaxWrapper).append( data );
                    }, 355);

                  },
                  error: function(xhr, type, exception) {
                    // if ajax fails display error alert
                  }
                });

            });

        };

        // ------------------------------------
        // METHODS
        // ------------------------------------

        this.AjaxShowNews = new AjaxShowNews().init();

    }.bind(AjaxControls, jQuery)());

});