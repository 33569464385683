
// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// MobileMenu Module

(function ($) {

    "use strict";

    var self;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function BlocFAQ() {

        self = this;

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    BlocFAQ.prototype.init = function () {

        var $titleBtn = $('.faq-title');

        $titleBtn.on('click', function() {
            $(this).closest('.faq-row').find('.faq-content').slideToggle();
            $(this).closest('.faq-row').toggleClass('is-active');
        });
    };

    this.BlocFAQ = new BlocFAQ();

}.bind(Deckperfo, jQuery)());
