
// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// blocPublications Module

(function ($) {

    "use strict";

    var self;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function blocPublications() {

        self = this;

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    blocPublications.prototype.init = function () {

        var categories = true;

        $('.show-hide-categories').click(function()
        {
            if(categories == true)
            {
                $(this).addClass('open');
                $('.sub-menu-link-wrap').show();
                categories = false;
            }else if (categories == false)
            {
                $(this).removeClass('open');
                $('.sub-menu-link-wrap').hide();
                categories = true;
            }
            
        });

        $('.print').click(function()
        {
            window.print();
        });

    };

    this.blocPublications = new blocPublications();

}.bind(Deckperfo, jQuery)());